import React, {Suspense, useEffect, useRef, useState} from 'react';
import {Canvas, useFrame, useThree} from "@react-three/fiber";
import {Environment, Html, CameraControls, useProgress, useFBX, Loader} from "@react-three/drei";
import CameraPositionLogger from "./CameraPositionLogger";
import HomeContent from "./HomeContent";
import PalazzoContent from "./PalazzoContent";
import {AnimatePresence} from "framer-motion";
import ShapesModalRoot from "./modals/ShapesModalRoot";
import MenuModalRoot from "./modals/MenuModalRoot";
import {useDispatch, useSelector} from "react-redux";
import {mainSelector, SetAnimation, SetSound, SwitchSection} from "../store/slices/main.slice";
import {AnimationMixer, LoopOnce, Quaternion, Vector3} from "three";

const HomePage = () => {
    const dispatch: any = useDispatch();
    const {active, progress, loaded, total} = useProgress()
    const [loading, setLoading] = useState<boolean>(true);
    const {section, zoom, sound, animation} = useSelector(mainSelector);
    const [rotationSpeed, setRotationSpeed] = useState(0)
    const [maxPolarAngle, setMaxPolarAngle] = useState(Math.PI)
    const cameraControlRef = useRef<CameraControls | null>(null);

    useEffect(() => {
        if (!active && loaded === total && progress === 100) {
            setLoading(false)
        }
    }, [active, progress, loaded, total]);

    useFBX.preload("images/PalazzoDucale.fbx")
    const Scene = React.memo(({animation}: any) => {
        const { camera } = useThree();
        const modelRef = useRef();
        const totalRotation = -(2 * Math.PI); // Full rotation (360 degrees)
        const totalFrames = rotationSpeed; // Total frames for the rotation
        const mixer: any = useRef(null);
        const actionRef: any = useRef(null);
        const initialPosition = useRef(new Vector3());
        const initialRotation = useRef(new Quaternion());
        const initialScale = useRef(new Vector3());

        const rotationIncrement = totalRotation / totalFrames;

        useFrame(() => {
            if(rotationSpeed > 0) {
                //@ts-ignore
                modelRef.current.rotation.y += rotationIncrement;
            }
        });

        //@ts-ignore
        let fbx = useFBX("images/PalazzoDucale12.fbx")

        useEffect(() => {
            if (fbx.animations.length) {
                mixer.current = new AnimationMixer(fbx);
                actionRef.current = mixer.current.clipAction(fbx.animations[0]);
                actionRef.current.setLoop(LoopOnce, 1);
                actionRef.current.clampWhenFinished = true;

                // Store the initial state of the model
                initialPosition.current.copy(fbx.position);
                initialRotation.current.copy(fbx.quaternion);
                initialScale.current.copy(fbx.scale);
            }
        }, [fbx]);

        useEffect(() => {
            if (mixer.current) {
                if(animation !== null && animation !== 'reset') {
                    setRotationSpeed(0)
                    cameraControlRef.current?.setLookAt(71.61,18.48, 13.56, 0, 8, -1, true)
                    actionRef.current.reset();
                    actionRef.current.setEffectiveTimeScale(1); // Play forward
                    actionRef.current.setEffectiveWeight(1); // Ensure the weight is set to 1
                    actionRef.current.play();
                }

                if(animation === 'reset') {
                    actionRef.current.reset();
                    actionRef.current.paused = false;
                    actionRef.current.time = actionRef.current.getClip().duration; // Set to the end of the animation
                    actionRef.current.setEffectiveTimeScale(-1); // Play in reverse
                    actionRef.current.setEffectiveWeight(1); // Ensure the weight is set to 1
                    actionRef.current.play();
                    setTimeout(() => {
                        //setRotationSpeed(10000)
                        //dispatch(SetAnimation(null))
                    }, actionRef.current.getClip().duration);
                }
            }
        }, [animation, fbx]);

        useFrame((state, delta) => {
            if (mixer.current) mixer.current.update(delta);
        });

        /*useFrame(() => {
            // Calculate the current lookAt value
            const direction = new Vector3(0, 0, -1).applyQuaternion(fbx.quaternion);
            const currentLookAt = new Vector3().copy(fbx.position).add(direction);
            console.log(`LookAt: ${currentLookAt.x.toFixed(2)}, ${currentLookAt.y.toFixed(2)}, ${currentLookAt.z.toFixed(2)}`);
        });*/

        //Camera position: x: 32.93, y: 19.12, z: 11.33 CameraPositionLogger.tsx:14
        //Camera rotation: x: -0.49, y: 1.27, z: 0.47 CameraPositionLogger.tsx:22
        //LookAt: 0.00, 0.00, -1.00

        return <primitive ref={modelRef} object={fbx} scale={0.005}/>;
    });

    useEffect(() => {
        if (!loading && section === 0) {
            initialPositioning()
        }
        if (!loading && section === 1) {
            changePosition()
        }
    }, [loading, section]);

    useEffect(() => {
        if (cameraControlRef.current) {
            const zoomLevel = 1 + (zoom / 100);
            //@ts-ignore
            cameraControlRef.current.zoomTo(zoomLevel, true);
        }
    }, [zoom]);

    const initialPositioning = () => {
        //@ts-ignore
        setMaxPolarAngle(Math.PI)
        if (cameraControlRef.current) cameraControlRef.current.smoothTime = 1
        cameraControlRef.current?.setLookAt(32.93, 19.12, 11.33, 0, 8, -1, true)
        /*cameraControlRef.current?.rotateAzimuthTo(1.40 * (180 / Math.PI))
        cameraControlRef.current?.pan(0, -5, true)*/
        cameraControlRef.current?.zoomTo(2, true)
    }

    const changePosition = () => {
        setMaxPolarAngle(Math.PI / 2.2)
        //setRotationSpeed(10000)
        //@ts-ignore
        dispatch(SwitchSection(1))
        if (cameraControlRef.current) cameraControlRef.current.smoothTime = 1
        cameraControlRef.current?.setLookAt(71.61,18.48, 13.56, 0, 8, -1, true)
        /*cameraControlRef.current?.pan(0, 0, true)
        cameraControlRef.current?.rotateAzimuthTo(1.40 * (2 / Math.PI), true)*/
        cameraControlRef.current?.zoomTo(2, true)
    }

    const handleZoom = (constant: number) => {
        cameraControlRef.current?.zoomTo(constant, true)
    }

    const handleSound = () => {
        dispatch(SetSound(!sound))
    }

    const CustomLoader = () => {
        return (
            <div className="w-[100vw] h-[100vh] flex flex-col justify-between items-center bg-[#1a1a1a] py-[50px]">
                <img src={'/images/GNDM acronimo orizzontale white.png'} alt="Header GNDM Logo" className="max-h-[20px]"/>
                <div className="flex flex-col justify-center items-center gap-[50px]">
                    <img src={'/images/loading.png'} alt="Loader" className="max-h-[100px]"/>
                    <span className="text-[#717171] text-[20px] font-untitledSans">{progress.toFixed()}%</span>
                </div>
                <button onClick={handleSound} className="w-[25px] h-[25px] min-w-[25px] min-h-[25px] flex flex-col items-center justify-center cursor-pointer"><img src={sound ? "/images/sound-icon.png" : "/images/sound_off.png"} alt="Sound icon" className="h-full"/></button>
            </div>
        )
    }


    return (
        <div className="h-[100vh] w-[100vw] overflow-hidden">
            <AnimatePresence>
                {!loading && section === 0 && <HomeContent key="home" changePosition={changePosition}/>}
                {!loading && section === 1 && <PalazzoContent key="palazzo" handleZoom={handleZoom}/>}
                <ShapesModalRoot children={null}/>
                <MenuModalRoot/>
            </AnimatePresence>
            <div className="absolute top-0 left-0 h-full w-full">
                {loading && <CustomLoader/>}
                <Canvas camera={{position: [-27, 14, -15]}}>
                    <Suspense fallback={null}>
                        <CameraControls ref={cameraControlRef} maxPolarAngle={maxPolarAngle}/>
                        <ambientLight intensity={3}/>
                        <rectAreaLight width={1000} height={1000} intensity={20} color={"ffffff"} position={[1, 1, 1]}/>
                        <Scene animation={animation}/>
                        <CameraPositionLogger event="mousedown"/>
                        <ambientLight intensity={3}/>
                        <Environment
                            files="./images/panorama_drone_v2.hdr"
                            background
                            blur={0}
                        />
                    </Suspense>
                </Canvas>
            </div>
        </div>
    );
};

export default HomePage;