import React, {useEffect, useRef} from 'react';
import './App.css';
import Main from "./components/Main";
import {useSelector} from "react-redux";
import {mainSelector} from "./store/slices/main.slice";

function App() {
    const audioRef: any = useRef();
    const { sound } = useSelector(mainSelector);

    // Audio
    const startAudio = () => {
        audioRef.current!.play();
    };

    const pauseAudio = () => {
        audioRef.current!.pause();
    };

    useEffect(() => {
        if(audioRef && audioRef.current) {
            if(sound) {
                startAudio()
            } else {
                pauseAudio()
            }
        }
    }, [sound, audioRef, audioRef.current]);

    return (
        <>
            <audio controls ref={audioRef} style={{opacity: 0, position: 'absolute'}}>
                <source src="./audio/1222926_the-traveler_preview.mp3" type="audio/mp3"/>
                Your browser does not support the audio element.
            </audio>
            <Main/>
        </>
    );
}

export default App;
