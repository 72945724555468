// @ts-nocheck
import React from 'react';

const IntFloorPlanPianoSecondo3 = () => {
    return (
        <>
            <svg className="interactive-floor-plan-map" style={{width: '100%'}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1109 587">
                <image xlinkHref="/images/interactive-floor-plans/Secondo piano/Piano_Secondo_P3-SALA-A3.png" style={{width: '1109px'}}></image>
                <a xlinkHref="/?room=6" target="_self" xlinkTitle="Sala A1">
                    <g>
                        <rect x="536.5194681861349" y="8.334283000949668" width="116.67996201329538" height="67.71604938271605" className="image-mapper-shape" data-index="15"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=7" target="_self" xlinkTitle="Sala A2">
                    <g>
                        <rect x="659.4501424501425" y="9.376068376068377" width="55.214624881291456" height="63.548907882241224" className="image-mapper-shape" data-index="1"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=9" target="_self" xlinkTitle="Sala A5">
                    <g>
                        <rect x="836.5536562203229" y="1.0417853751187085" width="36.46248812915485" height="50.00569800569801" className="image-mapper-shape" data-index="3"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=10" target="_self" xlinkTitle="Sala B">
                    <g>
                        <rect x="770.9211775878442" y="77.09211775878443" width="61.46533713200381" height="33.33713200379867" className="image-mapper-shape" data-index="4"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=11" target="_self" xlinkTitle="Sala C">
                    <g>
                        <rect x="769.8793922127255" y="119.80531813865147" width="59.3817663817664" height="59.38176638176637" className="image-mapper-shape" data-index="5"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=12" target="_self" xlinkTitle="Sala D3">
                    <g>
                        <rect x="832.386514719848" y="329.2041785375119" width="38.54605887939226" height="62.50712250712246" className="image-mapper-shape" data-index="6"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=14" target="_self" xlinkTitle="Sala E2-B">
                    <g>
                        <polygon className="image-mapper-shape" data-index="1" points="707.372,389.628 707.372,331.288 787.59,332.33"></polygon>
                    </g>
                </a>
                <a xlinkHref="/?room=13" target="_self" xlinkTitle="Sala E2-A">
                    <g>
                        <polygon className="image-mapper-shape" data-index="2" points="785.506,390.67 717.79,391.711 788.632,341.706"></polygon>
                    </g>
                </a>
                <a xlinkHref="/?room=14" target="_self" xlinkTitle="Sala E3">
                    <g>
                        <rect x="688.6201329534663" y="397.96201329534665" width="40.629629629629676" height="67.71604938271605" className="image-mapper-shape" data-index="8"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=15" target="_self" xlinkTitle="Sala E4">
                    <g>
                        <rect x="664.659069325736" y="334.4131054131054" width="39.587844254510856" height="56.25641025641022" className="image-mapper-shape" data-index="10"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=16" target="_self" xlinkTitle="Sala E6">
                    <g>
                        <rect x="564.64767331434" y="332.329534662868" width="56.25641025641028" height="59.38176638176634" className="image-mapper-shape" data-index="11"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=17" target="_self" xlinkTitle="Torre">
                    <g>
                        <rect x="534.4358974358975" y="333.3713200379867" width="29.16999050332379" height="120.84710351377015" className="image-mapper-shape" data-index="12"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=18" target="_self" xlinkTitle="Sala F1">
                    <g>
                        <rect x="374.00094966761634" y="410.46343779677113" width="81.25925925925924" height="63.548907882241224" className="image-mapper-shape" data-index="13"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=19" target="_self" xlinkTitle="Sala F2">
                    <g>
                        <rect x="378.16809116809117" y="338.58024691358025" width="153.14245014245017" height="69.79962013295346" className="image-mapper-shape" data-index="14"></rect>
                    </g>
                </a>
            </svg>
        </>
    );
};

export default IntFloorPlanPianoSecondo3;
