import React from 'react';
import Button from "./layout/Button";
import {motion} from 'framer-motion';
import Header from "./layout/Header";

const HomeContent = ({changePosition}: any) => {

    return (
        <motion.div
            key="home-content-wrapper"
            className="relative z-[99] h-full w-full flex flex-col justify-between items-center"
            initial={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
            animate={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
            exit={{ backgroundColor: 'rgba(0, 0, 0, 0)', transition: { duration: 1 } }}
        >
            <Header/>
            <motion.section
                key="home-content-section"
                className="flex flex-col items-center"
                initial={{opacity: 1}}
                animate={{opacity: 1}}
                exit={{opacity: 0, transition: {duration: 1}}}
            >
                <img src="/images/logo_citta_palazzo.svg" alt="Logo Città Palazzo" className={`max-h-[200px] h-full w-[auto]`}/>
                <h3 className="mt-[50px] text-center text-white text-base font-untitledSans leading-[30px]">Un viaggio interattivo alla scoperta della storia, degli ambienti e dei personaggi <br/>che hanno reso il Palazzo Ducale di Urbino una culla del Rinascimento italiano</h3>
                <div className="mt-[50px]">
                    <Button text="Inizia" onClick={changePosition}/>
                </div>
            </motion.section>
            <motion.footer
                key="home-content-footer"
                className="flex flex-row items-center h-max gap-[10px] pb-[30px]"
                initial={{opacity: 1}}
                animate={{opacity: 1}}
                exit={{opacity: 0, transition: {duration: 1}}}
            >
                <button className="text-white text-base font-untitledSans hover:underline underline">IT</button>
                <div className="bg-white w-[3px] h-[3px] min-w-[3px] min-h-[3px] rounded-full"/>
                <button className="text-white text-base font-untitledSans hover:underline">EN</button>
                <div className="bg-white w-[3px] h-[3px] min-w-[3px] min-h-[3px] rounded-full"/>
                <button className="text-white text-base font-untitledSans hover:underline">DE</button>
                <div className="bg-white w-[3px] h-[3px] min-w-[3px] min-h-[3px] rounded-full"/>
                <button className="text-white text-base font-untitledSans hover:underline">FR</button>
            </motion.footer>
        </motion.div>
    )
};

export default HomeContent;
