// @ts-nocheck
import React from 'react';

const IntFloorPlanPianoTerra3 = () => {
    return (
        <>
            <svg className="interactive-floor-plan-map" style={{width: '100%'}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1109 587">
                <image xlinkHref="/images/interactive-floor-plans/Piano terra/3_Piano_Terra_P3-Cortile.png" style={{width: '1109px'}}></image>
                <a xlinkHref="/?room=1" target="_self" xlinkTitle="Piazza">
                    <g>
                        <polygon className="image-mapper-shape" data-index="1" points="80.0418,227.487 172.722,256.976 370.72,260.136 369.667,3.15954 171.669,3.15954 81.095,224.328"></polygon>
                    </g>
                </a>
                <a xlinkHref="/?room=2" target="_self" xlinkTitle="Ingresso">
                    <g>
                        <rect x="375.98575498575497" y="180.0940170940171" width="116.90313390313395" height="47.393162393162385" className="image-mapper-shape" data-index="3"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=4" target="_self" xlinkTitle="Giardino">
                    <g>
                        <polygon className="image-mapper-shape" data-index="4" points="98.9991,471.825 365.454,568.718 368.613,341.231 141.126,338.071"></polygon>
                    </g>
                </a>
                <a xlinkHref="/?room=5" target="_self" xlinkTitle="Cappelletta">
                    <g>
                        <rect x="418.11301044634376" y="484.46343779677113" width="27.382716049382736" height="36.86134852801524" className="image-mapper-shape" data-index="1"></rect>
                    </g>
                </a>
            </svg>
        </>
    );
};

export default IntFloorPlanPianoTerra3;
