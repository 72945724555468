// @ts-nocheck
import React from 'react';

const IntFloorPlanPianoTerra4 = () => {
    return (
        <>
            <svg className="interactive-floor-plan-map" style={{width: '100%'}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1109 587">
                <image xlinkHref="/images/interactive-floor-plans/Piano terra/4_Piano_Terra_P4-Giardino.png" style={{width: '1109px'}}></image>
                <a xlinkHref="/?room=1" target="_self" xlinkTitle="Piazza">
                    <g>
                        <polygon className="image-mapper-shape" data-index="1" points="82.1481,227.487 190.626,261.189 371.773,261.189 368.613,3.15954 160.084,6.31909"></polygon>
                    </g>
                </a>
                <a xlinkHref="/?room=2" target="_self" xlinkTitle="Ingresso">
                    <g>
                        <rect x="382.3048433048433" y="179.04083570750237" width="107.42450142450144" height="47.39316239316241" className="image-mapper-shape" data-index="3"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=3" target="_self" xlinkTitle="Cortile">
                    <g>
                        <rect x="529.750237416904" y="123.22222222222223" width="206.4235517568851" height="169.56220322886986" className="image-mapper-shape" data-index="4"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=5" target="_self" xlinkTitle="Cappelletta">
                    <g>
                        <rect x="418.11301044634376" y="484.46343779677113" width="27.382716049382736" height="36.86134852801524" className="image-mapper-shape" data-index="1"></rect>
                    </g>
                </a>
            </svg>
        </>
    );
};

export default IntFloorPlanPianoTerra4;
