import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useDispatch, useSelector} from "react-redux";
import {MenuModalOpen, mainSelector, SwitchSection, InfoModalOpen} from "../../store/slices/main.slice";
import Header from "../layout/Header";

const MenuModalRoot = () => {
    const dispatch: any = useDispatch();
    const { menuModal, infoModal } = useSelector(mainSelector);

    const onClose = () => {
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(false))
    }

    const goToPalazzo = () => {
        dispatch(SwitchSection(1))
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(false))
        window.location.href = `/`
    }

    const goToVirtualTour = () => {
        dispatch(MenuModalOpen(false))
        dispatch(InfoModalOpen(false))
        window.location.href = `?room=${1}`;
    }

    // Handle scrolling
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [showScrollDown, setShowScrollDown] = useState(false);
    const scrollableDivRef = useRef(null);

    const checkScroll = () => {
        const div = scrollableDivRef.current;
        if (!div) return;

        //@ts-ignore
        const maxScroll = div.scrollHeight - div.offsetHeight;
        //@ts-ignore
        setShowScrollUp(div.scrollTop > 0);
        //@ts-ignore
        setShowScrollDown(div.scrollTop < maxScroll);
    };

    useEffect(() => {
        const div = scrollableDivRef.current;
        if (div) {
            const preventScroll = (e: any) => e.preventDefault();
            //@ts-ignore
            div.addEventListener('wheel', preventScroll, { passive: false });

            return () => {
                //@ts-ignore
                div.removeEventListener('wheel', preventScroll);
            };
        }
    }, []);

    useEffect(() => {
        checkScroll()
    }, [scrollableDivRef, infoModal, menuModal]);

    const scrollDown = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: 100, behavior: 'smooth' });
        }
    };

    const scrollUp = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: -100, behavior: 'smooth' });
        }
    };

    const Divider = () => {
        return (
            <div className="w-[90%] h-[1px] bg-accent my-[20px]"/>
        )
    }

    if(menuModal.open === true || infoModal.open === true) {
        return (
            <AnimatePresence>
                {menuModal.open === true && <motion.div
                    key="shapeModal-root"
                    className="fixed top-0 left-0 w-full h-full justify-center items-center z-[991] bg-[#302e2b]"
                    initial={{height: 0}}
                    animate={{height: '100vh', transition: {duration: 0.25, delay: 0}}}
                    exit={{height: 0, transition: {duration: 0.25, delay: 0.10}}}
                    onClick={onClose}
                >
                    <motion.div
                        key="shapeModal-content"
                        className="w-[100vw] h-[100dvh] bg-[#302e2b] flex flex-col items-center justify-center"
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {duration: 0.10, delay: 0.25}}}
                        exit={{opacity: 0, transition: {duration: 0.10, delay: 0}}}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <header className="absolute top-0 right-0 flex flex-row justify-between items-center w-full gap-[50px] h-[84px] sm:h-[60px] md:h-[84px]">
                            <Header/>
                        </header>
                        <div className="flex flex-col gap-[30px] w-max h-full items-center justify-center">
                            <h2 onClick={goToPalazzo} className="text-white text-xl font-untitledSans uppercase px-[100px] cursor-pointer">Palazzo 3D</h2>
                            <Divider/>
                            <h2 onClick={goToVirtualTour} className="text-white text-xl font-untitledSans uppercase cursor-pointer">Virtual Tour</h2>
                        </div>
                        <motion.footer
                            key="home-content-footer"
                            className="flex flex-row items-center h-max gap-[10px] pb-[30px]"
                            initial={{opacity: 1}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0, transition: {duration: 1}}}
                        >
                            <button className="text-white text-xs md:text-base font-untitledSans hover:underline underline">IT</button>
                            <div className="bg-black w-[3px] h-[3px] min-w-[3px] min-h-[3px] rounded-full"/>
                            <button className="text-white text-xs md:text-base font-untitledSans hover:underline">EN</button>
                            <div className="bg-black w-[3px] h-[3px] min-w-[3px] min-h-[3px] rounded-full"/>
                            <button className="text-white text-xs md:text-base font-untitledSans hover:underline">DE</button>
                            <div className="bg-black w-[3px] h-[3px] min-w-[3px] min-h-[3px] rounded-full"/>
                            <button className="text-white text-xs md:text-base font-untitledSans hover:underline">FR</button>
                        </motion.footer>
                    </motion.div>
                </motion.div>}
                {infoModal.open && (
                    <motion.div
                        key="shapeModal-root-info"
                        className="fixed top-0 left-0 w-full h-0 bg-[#302e2b] flex justify-center items-center z-[991] overflow-y-scroll md:overflow-hidden"
                        initial={{height: 0}}
                        animate={{height: '100vh', transition: {duration: 0.25, delay: 0}}}
                        exit={{height: 0, transition: {duration: 0.25, delay: 0.10}}}
                    >
                        <header className="absolute top-0 right-0 flex flex-row justify-between items-center w-full gap-[50px] h-[84px] sm:h-[60px] md:h-[84px] z-[999]">
                            <Header/>
                        </header>
                        <motion.div
                            key="shapeModal-content-info"
                            className="relative h-[100dvh] flex flex-col md:items-center md:justify-center p-[20px] pt-[60px] md:p-[60px] w-[90vw] md:w-[70vw]"
                            initial={{opacity: 0}}
                            animate={{opacity: 1, transition: {duration: 0.10, delay: 0.25}}}
                            exit={{opacity: 0, transition: {duration: 0.10, delay: 0}}}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex flex-col md:flex-row gap-[30px]">
                                <section className="w-full md:w-[50%]">
                                    <h2 className="text-white text-base md:text-md font-untitledSans font-medium mb-[15px]">Links</h2>
                                    <a href="https://gndm.it/" target="_blank" className="text-white text-xs md:text-sm font-untitledSans underline">gallerianazionalemarche.it</a>
                                    <div className="mt-[5px]">
                                        <span className="text-white text-xs md:text-sm font-untitledSans">Condividi:</span>
                                        <div className="flex flex-row items-center gap-[20px] mt-[10px]">
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/instragram-icon.png" alt="Instagram icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/whatsapp-icon.png" alt="Whatsapp icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/facebook-icon.png" alt="Facebook icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/x-icon.png" alt="X icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                            <button className={`w-[32px] flex flex-col items-center justify-center`} style={{height: "32px"}}>
                                                <img src="/images/linkdin-icon.png" alt="Linkdin icon" className={`max-h-[32px] h-full w-[auto]`}/>
                                            </button>
                                        </div>
                                    </div>
                                    <Divider/>
                                    <h2 className="text-white text-base md:text-md font-untitledSans font-medium mb-[15px]">Credits</h2>
                                    <p className="text-white text-xs md:text-sm font-untitledSans mb-[5px]">Ideazione, design, sviluppo, project management: <a href="https://www.taleormade.com/" target="_blank" className="underline">taleormade.com</a></p>
                                    <p className="text-white text-xs md:text-sm font-untitledSans">Project management GNDM: Filippo Gualazzi</p>
                                    <Divider/>
                                    <p className="text-white text-xs md:text-sm font-untitledSans mb-[5px] underline">Privacy</p>
                                    <p className="text-white text-xs md:text-sm font-untitledSans underline">Note legali</p>
                                </section>
                                <section className="overflow-hidden w-full md:w-[50%] pb-safe md:pb-0">
                                    <div className="max-h-[450px]">
                                        <h2 className="text-white text-base md:text-md font-untitledSans font-medium mb-[10px]">Lorem ipsum</h2>
                                        <p onScroll={checkScroll} ref={scrollableDivRef} className="scrollable-div text-white text-xs md:text-sm font-untitledSans text-justify h-[380px] overflow-y-auto">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                                            quidem
                                            rerum facilis est et expedita distinctio.
                                            <br/><br/>
                                            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas est, omnis dolor repellendus.
                                            Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
                                            Et harum quidem rerum facilis est et expedita distinctio quo minus id quod maxime placeat facere possimus.
                                            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas est, omnis dolor repellendus.
                                            Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
                                            Et harum quidem rerum facilis est et expedita distinctio quo minus id quod maxime placeat facere possimus.
                                        </p>
                                    </div>
                                    <section className="mt-[20px] flex flex-row items-center justify-between mb-[40px] md:mb-0">
                                        {!showScrollUp && <div/>}
                                        {showScrollUp && <button onClick={scrollUp} className="text-white text-xxs md:text-xs font-untitledSans font-bold h-[2opx] cursor-pointer">Leggi di meno</button>}
                                        {showScrollDown && <button onClick={scrollDown} className="text-white text-xxs md:text-xs font-untitledSans font-bold h-[2opx] cursor-pointer">Leggi di più</button>}
                                    </section>
                                </section>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        );
    }

    return null
};

export default MenuModalRoot;
