// @ts-nocheck
import React from 'react';

const IntFloorPlanSotterranei2 = () => {
    return (
        <>
            <svg className="interactive-floor-plan-map" style={{width: '100%'}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1055 366">
                <image xlinkHref="/images/interactive-floor-plans/Sotterranei/Piano_Seminterrato_P2_Disimpegno.png" style={{width: '1055px'}}></image>
                <a xlinkHref="/?room=21" target="_self" xlinkTitle="Sala F1">
                    <g>
                        <polygon className="image-mapper-shape" data-index="1" points="196.372,99.188 214.406,43.0817 326.619,43.0817 329.625,99.188"></polygon>
                    </g>
                </a>
                <a xlinkHref="/?room=28" target="_self" xlinkTitle="Sala A1">
                    <g>
                        <rect x="373.7084520417854" y="114.21652421652422" width="141.2678062678063" height="72.13675213675214" className="image-mapper-shape" data-index="2"></rect>
                    </g>
                </a>
                <a xlinkHref="?room=25" target="_self" xlinkTitle="Sala D">
                    <g>
                        <polygon className="image-mapper-shape" data-index="4" points="92.1747,252.479 115.218,185.351 302.574,250.475 295.56,327.621"></polygon>
                    </g>
                </a>
                <a xlinkHref="?room=23" target="_self" xlinkTitle="Sala A2">
                    <g>
                        <rect x="450.85470085470087" y="191.3627730294397" width="64.12155745489082" height="56.106362773029446" className="image-mapper-shape" data-index="5"></rect>
                    </g>
                </a>
                <a xlinkHref="?room=24" target="_self" xlinkTitle="Sala B1">
                    <g>
                        <rect x="373.7084520417854" y="221.41975308641975" width="75.14245014245012" height="34.06457739791074" className="image-mapper-shape" data-index="6"></rect>
                    </g>
                </a>
                <a xlinkHref="?room=26" target="_self" xlinkTitle="Sala B4">
                    <g>
                        <polygon className="image-mapper-shape" data-index="7" points="372.707,329.625 372.707,283.538 405.769,285.541 403.765,316.6"></polygon>
                    </g>
                </a>
            </svg>
        </>
    );
};

export default IntFloorPlanSotterranei2;
