import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import ImageSider from "../layout/ImageSider";
import {useDispatch, useSelector} from "react-redux";
import {mainSelector, SetAnimation, ShapeModalOpen} from "../../store/slices/main.slice";

const ShapesModalRoot = ({ children }: any) => {
    const dispatch: any = useDispatch();
    const { shapeModal } = useSelector(mainSelector);

    const images = [
        '/images/shapes-content/shape-1/image-1.png',
        '/images/shapes-content/shape-1/image-2.png',
        '/images/shapes-content/shape-1/mocked-map.png',
        '/images/shapes-content/shape-1/image-2.png',
    ]

    const onClose = () => {
        dispatch(SetAnimation('reset'))
        dispatch(ShapeModalOpen(false))
    }

    // Handle scrolling
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [showScrollDown, setShowScrollDown] = useState(false);
    const scrollableDivRef = useRef(null);

    const checkScroll = () => {
        const div = scrollableDivRef.current;
        if (!div) return;

        //@ts-ignore
        const maxScroll = div.scrollHeight - div.offsetHeight;
        //@ts-ignore
        setShowScrollUp(div.scrollTop > 0);
        //@ts-ignore
        setShowScrollDown(div.scrollTop < maxScroll);
    };

    useEffect(() => {
        const div = scrollableDivRef.current;
        if (div) {
            const preventScroll = (e: any) => e.preventDefault();
            //@ts-ignore
            div.addEventListener('wheel', preventScroll, { passive: false });

            return () => {
                //@ts-ignore
                div.removeEventListener('wheel', preventScroll);
            };
        }
    }, []);

    useEffect(() => {
        checkScroll()
    }, [scrollableDivRef, shapeModal]);

    const scrollDown = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: 100, behavior: 'smooth' });
        }
    };

    const scrollUp = () => {
        if (scrollableDivRef.current) {
            //@ts-ignore
            scrollableDivRef.current.scrollBy({ top: -100, behavior: 'smooth' });
        }
    };


    return (
        <AnimatePresence>
            {shapeModal?.open && (
                <motion.div
                    key="shapeModal-root"
                    className="fixed top-0 left-0 w-full h-full bg-black/65 flex justify-center items-center z-[100]"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1, delay: 1 }}}
                    exit={{ opacity: 0, transition: { duration: 0.5, delay: 0 } }}
                    onClick={onClose}
                >
                    <motion.div
                        key="shapeModal-content"
                        className="p-6 rounded-lg w-[70vw]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }}
                        exit={{ opacity: 0, transition: { duration: 0.5 }}}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row gap-[30px]">
                            <section className="pt-[80px]">
                                <div className={`w-[400px] h-[180px] flex flex-col items-center justify-center`}>
                                    <img src="/images/shapes-content/shape-1/mocked-map.png" alt="Plan map"  className={`h-full w-[auto]`}/>
                                </div>
                                <ImageSider images={images}/>
                            </section>
                            <section className="overflow-hidden">
                                <div className="mb-[20px] h-max w-full flex flex-row justify-between items-end">
                                    <div className={`w-[60px] h-[50px] flex flex-col items-center justify-center cursor-pointer`}>
                                        <img src="/images/gndm-icons/ico1.png" alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[45px] h-full w-[auto]`}/>
                                    </div>
                                    <button onClick={onClose} className="text-white text-md font-untitledSans leading-[15px]">X</button>
                                </div>
                                <div className="max-h-[400px]">
                                    <h2 className="text-white text-xl font-untitledSans">Alcova del <strong>Duca</strong></h2>
                                    <p onScroll={checkScroll} ref={scrollableDivRef} className="scrollable-div text-white text-sm font-untitledSans text-justify h-[285px] overflow-y-auto">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
                                        <br/><br/>
                                        Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas est, omnis dolor repellendus.
                                        Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
                                        Et harum quidem rerum facilis est et expedita distinctio quo minus id quod maxime placeat facere possimus.
                                    </p>
                                </div>
                                <section className="mt-[20px] flex flex-row items-center justify-between">
                                    {!showScrollUp && <div/>}
                                    {showScrollUp && <button onClick={scrollUp} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">Leggi di meno</button>}
                                    {showScrollDown && <button onClick={scrollDown} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">Leggi di più</button>}
                                </section>
                            </section>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ShapesModalRoot;
