// @ts-nocheck
import React from 'react';

const IntFloorPlanPianoTerra1 = () => {
    return (
        <>
            <svg className="interactive-floor-plan-map" style={{width: '100%'}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1109 587">
                <image xlinkHref="/images/interactive-floor-plans/Piano terra/1_Piano_Terra_P1-Piazza.png" style={{width: '1109px'}}></image>
                <a xlinkHref="/?room=2" target="_self" xlinkTitle="Ingresso">
                    <g>
                        <rect x="375.98575498575497" y="180.0940170940171" width="116.90313390313395" height="47.393162393162385" className="image-mapper-shape" data-index="3"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=3" target="_self" xlinkTitle="Cortile">
                    <g>
                        <rect x="528.6970560303894" y="115.84995251661918" width="211.68945868945866" height="183.25356125356126" className="image-mapper-shape" data-index="5"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=4" target="_self" xlinkTitle="Giardino">
                    <g>
                        <polygon className="image-mapper-shape" data-index="6" points="102.159,470.772 150.605,337.018 371.773,343.337 370.72,578.197"></polygon>
                    </g>
                </a>
                <a xlinkHref="/?room=5" target="_self" xlinkTitle="Cappelletta">
                    <g>
                        <rect x="418.11301044634376" y="484.46343779677113" width="27.382716049382736" height="36.86134852801524" className="image-mapper-shape" data-index="1"></rect>
                    </g>
                </a>
            </svg>
        </>
    );
};

export default IntFloorPlanPianoTerra1;
