import React, {useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useDispatch, useSelector} from "react-redux";
import {AGModalOpen, mainSelector} from "../../store/slices/main.slice";
import { isMobile, isTablet } from 'react-device-detect';
import Draggable from 'react-draggable';

const AGDrawerRoot = () => {
    const drawerRef = useRef(null);
    const dispatch = useDispatch();
    const {AGModal} = useSelector(mainSelector);

    const onClose = () => {
        //@ts-ignore
        dispatch(AGModalOpen(false));
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            //@ts-ignore
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle scrolling
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [showScrollDown, setShowScrollDown] = useState(false);
    const scrollableDivRef = useRef(null);
    const scrollableDivRefMobile = useRef(null);

    const checkScroll = () => {
        let div: any

        if(isMobile || isTablet) {
            div = scrollableDivRefMobile.current;
        } else {
            div = scrollableDivRef.current;
        }

        if (!div) return;

        //@ts-ignore
        const maxScroll = div.scrollHeight - div.offsetHeight;
        //@ts-ignore
        setShowScrollUp(div.scrollTop > 0);
        //@ts-ignore
        setShowScrollDown(div.scrollTop < maxScroll);
    };

    useEffect(() => {
        if(isMobile || isTablet) {
            const div = scrollableDivRef.current;
            if (div) {
                const preventScroll = (e: any) => e.preventDefault();
                //@ts-ignore
                div.addEventListener('wheel', preventScroll, { passive: false });

                return () => {
                    //@ts-ignore
                    div.removeEventListener('wheel', preventScroll);
                };
            }
        } else {
            const div = scrollableDivRef.current;
            if (div) {
                const preventScroll = (e: any) => e.preventDefault();
                //@ts-ignore
                div.addEventListener('wheel', preventScroll, { passive: false });

                return () => {
                    //@ts-ignore
                    div.removeEventListener('wheel', preventScroll);
                };
            }
        }
    }, []);

    useEffect(() => {
        checkScroll()
    }, [scrollableDivRef, scrollableDivRefMobile, AGModal]);

    const scrollDown = () => {
        if(isMobile || isTablet) {
            if (scrollableDivRefMobile.current) {
                //@ts-ignore
                scrollableDivRefMobile.current.scrollBy({ top: 100, behavior: 'smooth' });
            }
        } else {
            if (scrollableDivRef.current) {
                //@ts-ignore
                scrollableDivRef.current.scrollBy({ top: 100, behavior: 'smooth' });
            }
        }
    };

    const scrollUp = () => {
        if(isMobile || isTablet) {
            if (scrollableDivRefMobile.current) {
                //@ts-ignore
                scrollableDivRefMobile.current.scrollBy({ top: -100, behavior: 'smooth' });
            }
        } else {
            if (scrollableDivRef.current) {
                //@ts-ignore
                scrollableDivRef.current.scrollBy({ top: -100, behavior: 'smooth' });
            }
        }
    };

    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const videoRef = useRef(null);
    const videoRefMobile = useRef(null);
    const progressRef: any = useRef(null);
    const progressRefMobile: any = useRef(null);

    const handlePlayPause = () => {
        let video: any

        if(isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        if (isPlaying) {
            video!.pause();
        } else {
            video!.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        let video: any

        if(isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        const currentTime = video!.currentTime;
        const duration = video!.duration;
        const progressPercent = (currentTime / duration) * 100;
        setProgress(progressPercent);
    };

    const handleDrag = (e: any, data: any) => {
        let video: any

        if(isMobile || isTablet) {
            video = videoRefMobile.current;
        } else {
            video = videoRef.current;
        }

        const progressBar: any = progressRef.current;
        const newProgress = (data.x / progressBar.offsetWidth) * 100;
        const newTime = (newProgress / 100) * video!.duration;
        video.currentTime = newTime;
        setProgress(newProgress);
    };

    const divRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (divRef.current) {
                const { scrollTop } = divRef.current;
                setIsScrolled(scrollTop > 0);
            }
        };

        const divElement = divRef.current;
        if (divElement) {
            //@ts-ignore
            divElement.addEventListener('scroll', handleScroll);
        }

        // Clean up the event listener on component unmount
        return () => {
            if (divElement) {
                //@ts-ignore
                divElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [divRef, AGModal]);

    return (
        <AnimatePresence>
            {AGModal.open && (
                <motion.div
                    ref={drawerRef}
                    key="floor-maps-drawer--root"
                    className="fixed top-0 right-0 bottom-0 w-[500px] bg-[#302e2b] z-[100]"
                    initial={{x: '100%'}}
                    animate={{x: 0}}
                    exit={{x: '100%'}}
                    transition={{duration: 0.5}}
                >
                    <div className="flex flex-col h-full">
                        <section
                            className="min-h-[50%] flex-1 flex flex-col p-[40px]"
                            style={{
                                backgroundImage: `url(/images/rooms/room-1/AG/AG_sfondo.jpg)`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <button onClick={onClose} className="w-max h-max mx-w-[45px] mx-h-[45px] flex flex-col items-center justify-center mb-[20px]">
                                <img src="/images/close-drawer-icon.png" alt="AR icon" className="max-h-[20px] w-auto"/>
                            </button>
                            <h4 className="text-white text-2md font-untitledSans font-bold mb-[10px]">Un letto per l’inverno</h4>
                            <span className="text-white text-xs font-untitledSans mb-[5px]">#categoria</span>
                            <video
                                ref={videoRef}
                                onTimeUpdate={handleTimeUpdate}
                            >
                                <source src="/video/citta-ideale-gndm_1.mp4" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <div className="flex flex-row items-center gap-[10px] mt-[20px]">
                                <button
                                    onClick={handlePlayPause}
                                    className="cursor-pointer"
                                >
                                    <img src={isPlaying ? `/images/pause-icon.png` : `/images/play-icon.png`} alt="Play icon" className="h-[17px]"/>
                                </button>
                                <div ref={progressRef} className="relative bg-white h-[5px] w-full">
                                    <div
                                        className="bg-[#ec651f] h-full"
                                        style={{width: `${progress}%`}}
                                    ></div>
                                    <Draggable
                                        axis="x"
                                        bounds="parent"
                                        position={{x: (progress / 100) * progressRef.current?.offsetWidth || 0, y: 0}}
                                        onDrag={handleDrag}
                                    >
                                        <div className="absolute top-[-6px] w-[15px] h-[15px] bg-[#ec651f] rounded-full cursor-pointer"></div>
                                    </Draggable>
                                </div>
                            </div>
                        </section>
                        <section className="min-h-[50%] flex-1 flex flex-col px-[40px] pb-[40px] pt-[20px]">
                            <div className="overflow-y-auto flex-1">
                                <p onScroll={checkScroll} ref={scrollableDivRef} className="px-[15px] scrollable-div text-white text-xs font-untitledSans text-justify h-full overflow-y-auto">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum
                                    quidem rerum facilis est et expedita distinctio.
                                    <br/><br/>
                                    Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas est, omnis dolor repellendus.
                                    Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
                                    Et harum quidem rerum facilis est et expedita distinctio quo minus id quod maxime placeat facere possimus.
                                    Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas est, omnis dolor repellendus.
                                    Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
                                    Et harum quidem rerum facilis est et expedita distinctio quo minus id quod maxime placeat facere possimus.
                                    Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas est, omnis dolor repellendus.
                                    Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
                                    Et harum quidem rerum facilis est et expedita distinctio quo minus id quod maxime placeat facere possimus.
                                    Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas est, omnis dolor repellendus.
                                    Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
                                    Et harum quidem rerum facilis est et expedita distinctio quo minus id quod maxime placeat facere possimus.
                                </p>
                            </div>
                            <section className="mt-[20px] flex flex-row items-center justify-between">
                                {!showScrollUp && <div/>}
                                {showScrollUp && <button onClick={scrollUp} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">Leggi di meno</button>}
                                {showScrollDown && <button onClick={scrollDown} className="text-white text-xs font-untitledSans font-bold h-[20px] cursor-pointer">Leggi di più</button>}
                            </section>
                            <div className="mt-[50px] flex flex-col items-center">
                                <button className="min-w-[250px] border border-[1px] border-white flex flex-col items-center justify-center uppercase text-white text- font-untitledSans px-[50px] py-[8px]">Link esterno</button>
                            </div>
                        </section>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default AGDrawerRoot;
