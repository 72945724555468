import React, {useEffect, useRef} from 'react';
import {useThree} from "@react-three/fiber";

const CameraPositionLogger = ({ event }: any) => {
    const { camera } = useThree();
    const cameraRef = useRef(camera);

    useEffect(() => {
        const logCameraPosition = () => {
            const { x,y,z } = cameraRef.current.position
            const roundedX = Math.round(x * 100) / 100
            const roundedY = Math.round(y * 100) / 100
            const roundedZ = Math.round(z * 100) / 100
            console.log(`Camera position: x: ${roundedX}, y: ${roundedY}, z: ${roundedZ}`)
        }

        const logCameraRotation = () => {
            const { x,y,z } = cameraRef.current.rotation
            const roundedX = Math.round(x * 100) / 100
            const roundedY = Math.round(y * 100) / 100
            const roundedZ = Math.round(z * 100) / 100
            console.log(`Camera rotation: x: ${roundedX}, y: ${roundedY}, z: ${roundedZ}`)
        }

        const allLogs = () => {
            logCameraPosition()
            logCameraRotation()
        }

        cameraRef.current = camera
        window.addEventListener(event, allLogs)

        return () => {
            window.removeEventListener(event, allLogs)
        }
    }, []);

    return null

};

export default CameraPositionLogger;
