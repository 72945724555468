// @ts-nocheck
import React from 'react';

const IntFloorPlanPianoTerra2 = () => {
    return (
        <>
            <svg className="interactive-floor-plan-map" style={{width: '100%'}} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1109 587">
                <image xlinkHref="/images/interactive-floor-plans/Piano terra/2_Piano_Terra_P2-Ingresso.png" style={{width: '1109px'}}></image>
                <a xlinkHref="/?room=1" target="_self" xlinkTitle="Piazza">
                    <g>
                        <polygon className="image-mapper-shape" data-index="1" points="81.095,227.487 179.041,263.295 372.826,261.189 370.72,1.05318 182.2,2.10636"></polygon>
                    </g>
                </a>
                <a xlinkHref="/?room=3" target="_self" xlinkTitle="Cortile">
                    <g>
                        <rect x="526.5906932573599" y="116.9031339031339" width="209.58309591642922" height="177.98765432098764" className="image-mapper-shape" data-index="3"></rect>
                    </g>
                </a>
                <a xlinkHref="/?room=4" target="_self" xlinkTitle="Giardino">
                    <g>
                        <polygon className="image-mapper-shape" data-index="4" points="100.052,472.878 364.401,567.665 368.613,341.231 140.073,341.231"></polygon>
                    </g>
                </a>
                <a xlinkHref="/?room=5" target="_self" xlinkTitle="Cappelletta">
                    <g>
                        <rect x="418.11301044634376" y="484.46343779677113" width="27.382716049382736" height="36.86134852801524" className="image-mapper-shape" data-index="1"></rect>
                    </g>
                </a>
            </svg>
        </>
    );
};

export default IntFloorPlanPianoTerra2;
