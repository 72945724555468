import React from 'react';
import {motion} from "framer-motion";
import Button from "./layout/Button";
import {useDispatch} from "react-redux";
import {SetAnimation, ShapeModalOpen} from "../store/slices/main.slice";
import Header from "./layout/Header";
import 'react-range-slider-input/dist/style.css';
// @ts-ignore
import RangeSlider from 'react-range-slider-input';

const PalazzoContent = ({handleZoom}: any) => {
    const dispatch: any = useDispatch();
    const iconSize = 50
    const dividerSize = 35

    const openModal = (animation = 0) => {
        dispatch(ShapeModalOpen(true))
        dispatch(SetAnimation(animation))
    }

    const Divider = () => {
        return (
            <div className={`w-[10px] flex flex-col items-center justify-center`} style={{height: dividerSize}}>
                <img src="/images/gndm-icons/shape_divider.png" alt="Shape divider icon"  className={`max-h-[25px] 2xl:max-h-[35px] h-full w-[auto]`}/>
            </div>
        )
    }

    const handleZoomSlider = (e: any) => {
        handleZoom((100/e[1]))
    }

    const handleRoomChange = (room: number) => {
        window.location.href = `?room=${room}`;
    }

    return (
        <>
            <div className="relative z-[99] h-full w-full h-max flex flex-col justify-between items-center">
                <Header/>
            </div>
            <section className="flex flex-col items-center gap-[30px] justify-center h-full w-[50px] absolute left-[50px] z-[99] pb-[200px]">
                <img src="./images/icon_zoom_in.svg" alt="Icon zoom in" className="max-h-[30px]"/>
                <div className="h-[300px]">
                    <RangeSlider
                        className="single-thumb"
                        defaultValue={[1, 50]}
                        thumbsDisabled={[true, false]}
                        rangeSlideDisabled={true}
                        orientation={"vertical"}
                        max={90}
                        min={1}
                        onInput={(e: any) => handleZoomSlider(e)}
                    />
                </div>
                <img src="./images/icon_zoom_out.svg" alt="Icon zoom out" className="max-h-[30px]"/>
            </section>
            <motion.section
                key="palazzo-content-right"
                className="flex flex-col items-center justify-center gap-[15px] 2xl:gap-[20px] h-[100vh] w-max absolute top-0 right-0 z-[98] px-[60px] py-[40px] pt-[115px]"
                initial={{opacity: 0}}
                animate={{opacity: 1, transition: {duration: 2}}}
            >
                <button onClick={() => openModal(0)} style={{maxHeight: `${iconSize}px!important`}} className={`gndm-icon w-[58px] h-[45px] max-h-[35px] 2xl:max-h-[45px] flex flex-col items-center justify-center cursor-pointer`}>
                    <img src="/images/gndm-icons/ico1_on.png" alt="GNDM shape icon" style={{display: 'none'}} className={`max-h-[45px] h-full w-[auto]`}/>
                    <img src="/images/gndm-icons/ico1.png" alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[45px] h-full w-[auto]`}/>
                </button>
                <Divider/>
                <button onClick={() => openModal(0)} style={{maxHeight: `${iconSize}px!important`}} className={`gndm-icon w-[58px] h-[45px] max-h-[35px] 2xl:max-h-[45px] flex flex-col items-center justify-center cursor-pointer`}>
                    <img src="/images/gndm-icons/ico2_on.png" alt="GNDM shape icon" style={{display: 'none'}} className={`max-h-[45px] h-full w-[auto]`}/>
                    <img src="/images/gndm-icons/ico2.png" alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[45px] h-full w-[auto]`}/>
                </button>
                <Divider/>
                <button onClick={() => openModal(0)} style={{maxHeight: `${iconSize}px!important`}} className={`gndm-icon w-[50px] h-[42px] max-h-[32px] 2xl:max-h-[42px] flex flex-col items-center justify-center cursor-pointer`}>
                    <img src="/images/gndm-icons/ico3_on.png" alt="GNDM shape icon" style={{display: 'none'}} className={`max-h-[42px] h-full w-[auto]`}/>
                    <img src="/images/gndm-icons/ico3.png" alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[42px] h-full w-[auto]`}/>
                </button>
                <Divider/>
                <button onClick={() => openModal(0)} style={{maxHeight: `${iconSize}px!important`}} className={`gndm-icon w-[58px] h-[45px] max-h-[35px] 2xl:max-h-[45px] flex flex-col items-center justify-center cursor-pointer`}>
                    <img src="/images/gndm-icons/ico4_on.png" alt="GNDM shape icon" style={{display: 'none'}} className={`max-h-[45px] h-full w-[auto]`}/>
                    <img src="/images/gndm-icons/ico4.png" alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[45px] h-full w-[auto]`}/>
                </button>
                <Divider/>
                <button onClick={() => openModal(0)} style={{maxHeight: `${iconSize}px!important`}} className={`gndm-icon w-[64px] h-[50px] max-h-[40px] 2xl:max-h-[50px] flex flex-col items-center justify-center cursor-pointer`}>
                    <img src="/images/gndm-icons/ico5_on.png" alt="GNDM shape icon" style={{display: 'none'}} className={`max-h-[50px] h-full w-[auto]`}/>
                    <img src="/images/gndm-icons/ico5.png" alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[50px] h-full w-[auto]`}/>
                </button>
                <Divider/>
                <button onClick={() => openModal(0)} style={{maxHeight: `${iconSize}px!important`}} className={`gndm-icon w-[58px] h-[45px] max-h-[35px] 2xl:max-h-[45px] flex flex-col items-center justify-center cursor-pointer`}>
                    <img src="/images/gndm-icons/ico6_on.png" alt="GNDM shape icon" style={{display: 'none'}} className={`max-h-[45px] h-full w-[auto]`}/>
                    <img src="/images/gndm-icons/ico6.png" alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[45px] h-full w-[auto]`}/>
                </button>
                <Divider/>
                <button onClick={() => openModal(0)} style={{maxHeight: `${iconSize}px!important`}} className={`gndm-icon w-[58px] h-[45px] max-h-[35px] 2xl:max-h-[45px] flex flex-col items-center justify-center cursor-pointer`}>
                    <img src="/images/gndm-icons/ico7_on.png" alt="GNDM shape icon" style={{display: 'none'}} className={`max-h-[45px] h-full w-[auto]`}/>
                    <img src="/images/gndm-icons/ico7.png" alt="GNDM shape icon" style={{display: 'flex'}} className={`max-h-[45px] h-full w-[auto]`}/>
                </button>
            </motion.section>
            <motion.footer
                key="palazzo-content-footer"
                className="flex flex-row items-center justify-between h-max gap-[10px] absolute bottom-0 left-0 z-[98] w-full p-[40px] pb-[60px]"
                initial={{opacity: 0}}
                animate={{opacity: 1, transition: {duration: 2}}}
            >
                <button className="w-[75px] h-[45px]">
                    <img src="/images/RA-icon.png" alt="RA icon" className="max-h-[45px]"/>
                </button>
                <Button text="Accedi al virtual tour" onClick={() => handleRoomChange(1)}/>
                <div className="w-[75px]"/>
            </motion.footer>
        </>
    )
};

export default PalazzoContent;
